import React from 'react';
import { Link } from 'gatsby';
import homeicon from '../images/home.svg';
import rarr from '../images/rarr.svg';
import larr from '../images/larr.svg';
import './style.scss';
import textcomp from '../images/textcomp.svg';
import download from '../images/download.svg';
import captspec from '../images/capt-spec.svg';
import gillspec from '../images/gill-spec.svg';
import openspec from '../images/open-spec.svg';
import fontstack from '../images/fontstack.svg';

var pageTitle = "Typography";

const TypePage = () => (
	<div>

		<section className="pagetitle earthwarebg typohead">
			<div className="container">
			<h1 className="title white capitol">{pageTitle}</h1>
			</div>
		</section>

		<section className="pbt-section-small typehero section">
			<div className="container">
			<div className="columns">
				
				<div className="column pr-6">
				<h2 className="is-size-1 capitol steel">Well Lettered</h2>
				<p className="mb-6">The typefaces chosen for a brand build the foundation for its style. For Baker Barrios we have chosen classic, flexible, and professional fonts that work in all situations. Consistent usage helps establish brand equity and appeals to your audience.</p>
				</div>

				<div className="column  is-justify-content-center  is-align-items-center is-flex">
					<div className="logostage ">
						<img src={textcomp}/>
					</div>
				</div>

			</div>
			</div>
		</section>

		<section className="pbt-section-small whitebg section">
			<div className="container">
			<div className="columns">
				<div className="column is-one-third">
					<h4 className="iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">Headline</h4>
					<h2 className="is-size-1 capitol steel">Capitolina</h2>
					<p className="caption mb-5">Capitolina is a strong, architectural, and professional serif typeface. It is excellent for headlines and formal applications. Regular weight is preferred for larger size titles though bold can be used for subheadlines and callouts.</p>
					<p className="note mb-5">*Capitolina is availalbe through Abobe Creative Cloud. Please activate the font through their software. Contact the graphics department if you need any help.</p>

					{/* <a className="button is-primary" href="#"><span className="mr-5">Download Capitolina</span> <span className="icon is-small"><img src={download}/></span> </a> */}
				</div>
				<div className="column is-two-third has-text-centered">
				<h4 className="iconh sectitle is-uppercase youngvine tracked has-text-weight-bold mont is-size-7 mb-6">Specimen</h4>
				<img src={captspec}/>
				</div>
			</div>
			</div>
		</section>

		<section className="pbt-section-small section">
			<div className="container">
			<div className="columns">
				<div className="column is-one-third">
					<h4 className="iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">Callout | Subheader</h4>
					<h2 className="is-size-1 steel gill">Gill Sans</h2>
					<p className="caption mb-5">Gill sans is a geopmetric, humanist sans-serif font. Loaded with character, it works well as highlight text or a subheader where attention is needed. Works well in uppercase for titles. Has a wide variety of weights for use in any scenario.</p>
					<p className="note mb-5">*Gill Sans Nova is available through Microsoft and likely already on your machine. Please check and if you have any issues contact the graphics department.</p>
				</div>
				<div className="column is-two-third has-text-centered">
				<h4 className="iconh sectitle is-uppercase youngvine tracked has-text-weight-bold mont is-size-7 mb-6">Specimen</h4>
				<img src={gillspec}/>
				</div>
			</div>
			</div>
		</section>

		<section className="pbt-section-small whitebg section">
			<div className="container">
			<div className="columns">
				<div className="column is-one-third">
					<h4 className="iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">Body Copy</h4>
					<h2 className="is-size-1 steel open">Open Sans</h2>
					<p className="caption mb-5">Created as a super screen-friendly typeface, Open Sans has seen wide adoption thanks to its legibility at all sizes. Professional and clean, it is used as our body typeface to ensure our message always gets across with ease.</p>
					<a className="button is-primary" target="_blank" href="https://fonts.google.com/specimen/Open+Sans"><span className="mr-5">Download Open Sans</span> <span className="icon is-small"><img src={download}/></span> </a>
				</div>
				<div className="column is-two-third has-text-centered">
				<h4 className="iconh sectitle is-uppercase youngvine tracked has-text-weight-bold mont is-size-7 mb-6">Specimen</h4>
				<img src={openspec}/>
				</div>
			</div>
			</div>
		</section>

		<section className="pbt-section-small section">
			<section className="pbt-section-small">
				<div className="container">
					<div className="columns">
						<div className="column">
						<h4 className="iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">Hierarchy</h4>
						<h2 className="is-size-2 steel capitol">Sample Layout</h2>
						<p className="mb-5 pr-6">This block demonstrates the relationship between these typefaces. The headline (Capitolina) should carry the most visual weight, followed by the subheader (Gill Sans). The body font (Open Sans) while the smallest in size should retain legibility wherever used.</p>
						</div>
						<div className="column stonebg white is-flex is-flex-direction-column is-justify-content-center">
						<div className="textexcolor">
						<h3 className="capitol is-size-2 patina mb-4">Headline Text</h3>
						<h4 className="is-uppercase oldgold has-text-weight-bold caption mb-1">Subheader for more information</h4>
						<p className="bodysmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et congue pellentesque non nunc malesuada ipsum. Scelerisque bibendum aenean aliquam id vel tellus mi. Adipiscing nunc in erat augue et vestibulum mauris sit. Nibh quam ullamcorper eu nunc justo. Viverra sagittis, risus sagittis, amet vitae arcu aliquam massa. Consectetur sed in vitae platea pellentesque non lectus. Gravida felis odio ornare.</p>
					</div>
					</div>
					</div>
				</div>
			</section>

			<section className="pbt-section-small">
				<div className="container">
				<div className="columns is-align-items-center">
					<div className="column is-one-third">
						<h3 className="is-size-2 capitol steel mb-0">Font Stack</h3>
						<p className="caption pr-4">There may be instances where the brand fonts are not readily available. Email, word processing, or certain programs may not allow the use of custom fonts. For these cases we have developed a “font-stack”, or order of use, to help you decide which will be best to stay on brand.</p>
					</div>
					<div className="column has-text-centered">
						<img src={fontstack}/>
					</div>
				</div>
				</div>
			</section>

		</section>


		<div className='footer-nav'>
			<nav className='navbarfoot'>
				<div className='container spotgrid'>
					<Link className='prevpage' to='/color'>
					<img src={larr} alt='Left Arrow' /> Color 
					</Link>
					<div className="has-text-centered">
					<Link className='homebut' to='/'>
						<img src={homeicon} alt='Home' />
					</Link>
					</div>
					<span></span>
				</div>
			</nav>
		</div>

	</div>
);

export default TypePage;
